














































































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {required} from 'vee-validate/dist/rules';
import {ValidationProvider, ValidationObserver, extend} from 'vee-validate';
import {RouteService} from '../../store/category/RouteService';
import {Route} from '@/models/category/Route';
import vSelect from 'vue-select'
import debounce from 'lodash/debounce';
import filter from 'lodash/filter';
import i18n from "@/i18n";
import {RelateRoute} from "@/models/category/RelateRoute";

extend('required', {
    ...required,
    message: (_: string, values: any) => i18n.t('validations.required', [_])
} as any);

@Component({
    components: {
        vSelect,
        ValidationProvider,
        ValidationObserver
    }
})

export default class RoutePage extends Vue {
    currentPage: number = 1;
    rows: any = [];
    totalPages = 0;
    pageSize = 10;
    activePrompt: Boolean = false;
    popupRelateActive: boolean = false;
    isAdd: Boolean = false;
    keyword: string = '';
    selected: any = [];
    private routeService: RouteService;
    backgroundLoading: string = 'primary';
    colorLoading: string = '#fff';
    totalRows: number = 0;
    inventories: any = [];
    route: Route = new Route();
    invalid: any = false;
    currentRow: any = null;
    debounce: any = null;
    isSelectItem: boolean = false;
    relateRoutes: Array<RelateRoute> = new Array<RelateRoute>();
    id: string = "";

    constructor() {
        super();
        this.routeService = new RouteService();
    }

    beforeMount() {
    }

    mounted() {
        this.search();
    }

    async setPageSize(size: number) {
        this.pageSize = size;
        this.search();
    }

    @Watch('keyword')
    onKeyWordInputChange(value: string, oldValue: string) {
        if (this.debounce) {
            this.debounce.cancel();
        }

        this.debounce = debounce(() => this.search(), 500);
        this.debounce();
    }

    @Watch('currentPage')
    onCurrentPageChange(value: string, oldValue: string) {
        this.search();
    }

    onSearch(search: string, loading: any) {
        if (this.isSelectItem) {
            return;
        }

        loading(true);

        if (this.debounce) {
            this.debounce.cancel();
        }

        this.debounce = debounce(() => this.suggestRelateStress(search, loading), 500);
        this.debounce();
    }

    openPopup(item: Route) {
        this.popupRelateActive = true;

        this.route = item;
    }

    setSelected(value: string) {
        this.isSelectItem = true;

        setTimeout(() => {
            this.isSelectItem = false;
        }, 500);
    }

    async suggestRelateStress(search: string, loading: any) {
        if (search.length == 0) {
            loading(false);
            return;
        }

        const result = await this.routeService.getRelate(this.route.id, search, 100);

        loading(false);

        if (result.code > 0) {
            this.relateRoutes = result.items;
        } else {
            console.log(result);
        }
    }

    showAddForm() {
        this.activePrompt = true;
        this.isAdd = true;
        this.route = new Route();
    }

    setUpdate(item: Route) {
        this.isAdd = false;
        this.route = new Route();
        this.route.id = item.id;
        this.route.name = item.name;
        this.route.relateRoute = item.relateRoute;
        this.route.description = item.description;
        this.activePrompt = true;
        this.currentRow = item;
    }

    async changeActive(item: any) {
        const result = await this.routeService.changeActive(item.id, item.isActive);

        if (result.code > 0) {
            this.$vs.notify({
                title: "Thông báo",
                text: "Lưu thành công",
                color: 'primary',
                iconPack: 'feather',
                icon: 'icon-info'
            });
        } else {
            console.log(result);
        }
    }

    openConfirm(item: any) {
        this.$vs.dialog({
            type: 'confirm',
            color: 'danger',
            title: `Xác nhận`,
            text: `Bạn có chắc chắn muốn xóa điểm trung chuyển "${item.name}" không?`,
            accept: this.delete
        })

        this.currentRow = item;
    }

    async delete() {
        let id = this.currentRow.id;
        const result = await this.routeService.delete(id);

        this.$vs.notify({
            title: result.title, text: result.message, color: (result.code > 0 ? 'primary' : 'danger'),
            iconPack: 'feather', icon: 'icon-info'
        });

        this.rows = filter(this.rows, function (obj) {
            return obj.id != id;
        });
    }

    async save() {
        this.$vs.loading({
            background: this.backgroundLoading,
            color: this.colorLoading,
            container: "#btnSave",
            scale: 0.45
        })

        this.$vs.loading({
            background: this.backgroundLoading,
            color: this.colorLoading,
            container: "#btnSave2",
            scale: 0.45
        })

        this.invalid = false;

        if (this.isAdd) {
            const result = await this.routeService.insert(this.route);

            this.$vs.notify({
                title: "Thông báo",
                text: result.message,
                color: result.code > 0 ? 'primary' : 'warning',
                iconPack: 'feather',
                icon: 'icon-info'
            });

            if (result.code > 0) {
                this.search();
                this.activePrompt = false;
            }
        } else {
            const result = await this.routeService.update(this.route);

            this.$vs.notify({
                title: "Thông báo",
                text: result.message,
                color: result.code > 0 ? 'primary' : 'warning',
                iconPack: 'feather',
                icon: 'icon-info'
            });

            if (result.code > 0) {
                this.popupRelateActive = false;
                this.activePrompt = false;
                this.currentRow.name = this.route.name;
                this.currentRow.description = this.route.description;
                this.currentRow.relateRoute = this.route.relateRoute;
            }
        }

        setTimeout(() => {
            this.$vs.loading.close("#btnSave > .con-vs-loading")
            this.$vs.loading.close("#btnSave2 > .con-vs-loading")
        }, 500);
    }

    openLoading() {
        this.$vs.loading()
    }

    beginSearch() {
        if (this.keyword == undefined || this.keyword.length < 2) {
            return;
        }

        this.search();
    }

    private async search() {
        this.openLoading();
        const result = await this.routeService.search(this.keyword, this.currentPage, this.pageSize);

        if (result.code > 0) {
            this.rows = result.items;
            this.setTotalPage(result.totalRows);
        }

        setTimeout(() => {
            this.$vs.loading.close()
        }, 500);
    }

    private setTotalPage(totalRows: number) {
        this.totalRows = totalRows;

        if (totalRows == 0) {
            this.totalPages = 0;
        }
        if (totalRows <= this.pageSize) {
            this.totalPages = 1;
        }
        this.totalPages = Math.ceil((totalRows / this.pageSize));
    }
}
